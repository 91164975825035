// https://github.com/amrnn90/breeze-nuxt/blob/master/pages/login.vue

import { get } from "http";

export type User = {
  id?: string | null; 
  name: string | null;
  email?: string | null;
  status?: string | null;
  link?: string | null; 
  avatar?: string | null; 
  avatar_little?: string | null;
  avatar_small?: string | null;
  nutrient?: string | null;
  tds?: string | null;
  temperature?: string | null;
  volume?: string | null;
  weight?: string | null;
  length?: string | null;
  items_priv?: Array<any> | null;
  items_preset?: Object | null;
  items_access?: Object | null;
  is_b2b?: string | null;
};

export type LoginCredentials = {
  name: string;
  password: string;
};

export type RegisterCredentials = {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
};

export type ResetPasswordCredentials = {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
};

// Value is initialized in: ~/plugins/auth.ts
export const useAuthUser = () => {
  // return useState<User | undefined | null>("user", () => undefined);
  return useState<User | undefined | null>("user", function(){
    return { 
      id: null,
      name: null,
      email: null,
      status: null,
      link: null,
      avatar: null,
      avatar_small: null,
      nutrient: null,
      tds: null,
      temperature: null,
      volume: null,
      weight: null,
      length: null,
     };
  });
};
 


export const useAuth = () => {
  const router = useRouter();

  const user = useAuthUser();
  const isLoggedIn = computed(() => user.value?.id ? true : false);
  // getCookieDefault();

  async function refresh() {
    try {
      user.value = await fetchCurrentUser();
    } catch {
      // user.value = null;
    }
  }

  // getters

  // function getCookieDefault() {
  //   user.value.temperature = getCookie('unit_temperature');
  // }

  function getGuest() {
    if(!useCookie("auth:guest",{maxAge: 60 * 60 * 24 * 31 * 6}).value){
      return setGuest();
    }
    return useCookie("auth:guest",{maxAge: 60 * 60 * 24 * 31 * 6}).value;
  }

  function setGuest() {
    var hash = useCookie("auth:guest", { path: '/', maxAge: 60 * 60 * 24 * 31 * 6})
    hash.value = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    return hash.value;
  }

  function isAuth() {
    return isLoggedIn.value;
  }

  function setDefaultUser() {
    user.value.id = null;
    user.value.name = null;
    user.value.email = null;
    user.value.status = null;
    user.value.link = null;
    user.value.avatar = null;
    user.value.avatar_little = null;
    user.value.avatar_small = null;
    user.value.volume = getCookie('unit_volume') ?? 'l';
    user.value.nutrient = getCookie('unit_nutrient') ?? 'mll';
    user.value.temperature = getCookie('unit_temperature') ?? 'c';
    user.value.tds = getCookie('unit_tds') ?? 'ppm';
    user.value.weight = getCookie('unit_weight') ?? 'metric';
    user.value.length = getCookie('unit_length') ?? 'metric';
  }

  function setDefaultUnit() {
    console.log('setting default unit');
    user.value.id = null;
    user.value.name = null;
    user.value.email = null;
    user.value.status = null;
    user.value.link = null;
    user.value.avatar = null;
    user.value.avatar_little = null;
    user.value.avatar_small = null;
    user.value.volume = getCookie('unit_volume') ?? 'l';
    user.value.nutrient = getCookie('unit_nutrient') ?? 'mll';
    user.value.temperature = getCookie('unit_temperature') ?? 'c';
    user.value.tds = getCookie('unit_tds') ?? 'ppm';
    user.value.weight = getCookie('unit_weight') ?? 'metric';
    user.value.length = getCookie('unit_length') ?? 'metric';
 

    // self::$volume = $volume ? $volume : 'gal';
    // self::$nutrient = $nutrient ? $nutrient : 'mlgal';
    // self::$temperature = $temperature ? $temperature : 'f';
    // self::$tds = $tds ? $tds : 'ppm';
    // self::$weight = $weight ? $weight : 'english';
    // self::$length = $length ? $length : 'english';
  }

  function isOwner(user_id: string) {
    return user.value?.id && user.value.id == user_id;
  }

  function getId() {
    return user.value?.id;
  }
  function getName() {
    return user.value?.name;
  }
  function getAvatarLittle() {
    return user.value?.avatar_little;
  }
  function getAvatarSmall() {
    return user.value?.avatar_small;
  }
  function getLink() {
    return user.value?.link;
  }
  function getStatus() {
    return user.value?.status;
  }
  function getNutrient() {
    // return user.value?.nutrient ?? getCookie('unit_nutrient');
    const nutrient = user.value?.nutrient || getCookie('unit_nutrient');
    if (!nutrient) return 'mll';
    return nutrient;
  }
  function getTds() {
    // return user.value?.tds ?? getCookie('unit_tds');
    const tds = user.value?.tds || getCookie('unit_tds');
    if (!tds) return 'ppm';
    return tds;

  }
  function getTemperature() {
    // return user.value?.temperature ? user.value.temperature : useCookie('unit_temperature',{maxAge: 60 * 60 * 24 * 31 * 6}).value;
    const temperature = user.value?.temperature || getCookie('unit_temperature');
    if (!temperature) return 'c';
    return temperature;
  }
  function getVolume() {
    // return user.value?.volume ?? getCookie('unit_volume');
    const volume = user.value?.volume || getCookie('unit_volume');
    if (!volume) return 'l';
    return volume;
  }
  function getWeight() {
    // return user.value?.weight ?? getCookie('unit_weight');
    const weight = user.value?.weight || getCookie('unit_weight');
    if (!weight) return 'metric';
    return weight;
  }
  function getLength() {
    // return user.value?.length ?? getCookie('unit_length');
    const length = user.value?.length || getCookie('unit_length');
    if (!length) return 'metric';
    return length;
  }
  function getPreset(name: string) {
    let preset = (user.value?.items_preset || []).find(e => e.name === name);
    return preset?.val ?? '';
  }
  function getAccess(name: string) {
    var access = user.value?.items_access ? user.value?.items_access : {};
    if(access[name]){
      return access[name];
    }
    return null;
  }
  function existsAccess(name: string) {
    let access = (user.value?.items_access || []).find(e => e.name === name);
    return access?.val ?? '';
  }
  function isB2B() {
    return user.value?.is_b2b;
  }

  // setters

  function setNutrient(nutrient: string) {
    setCookie('unit_nutrient', nutrient);
    user.value.nutrient = nutrient;
    saveUnits('nutrient', nutrient);
  }
  function setTds(tds: string) {
    setCookie('unit_tds', tds);
    user.value.tds = tds;
    saveUnits('tds', tds);
  }
  function setTemperature(temperature: string) {
    setCookie('unit_temperature', temperature);
    console.log(user.value);
    user.value.temperature = temperature;
    saveUnits('temperature', temperature);
  }
  function setVolume(volume: string) {
    setCookie('unit_volume', volume);
    user.value.volume = volume;
    saveUnits('volume', volume);
  }
  function setWeight(weight: string) {
    setCookie('unit_weight', weight);
    user.value.weight = weight;
    saveUnits('weight', weight);
  }
  function setLength(length: string) {
    setCookie('unit_length', length);
    user.value.length = length;
    saveUnits('length', length);
  } 

  async function saveUnits(type: string, vl: string) {
    // if (isLoggedIn.value)
    //   return;
    
    if(!user.value?.id) 
      return;
 
    let data = {};
    data[type] = vl;
    // console.log(data);
    await useCustomFetch("/api/growers/" + user.value.id, { method: "PATCH", body: JSON.stringify(data) });
    await refresh();
  }

  async function setCookieToken(token: string) {
    const auth_cookie = useCookie("auth:token", { path: '/', maxAge: 60 * 60 * 24 * 31 * 6 });
    auth_cookie.value = token || null;
  }

  function getCookie(name : string) {
    return useCookie(name,{maxAge: 60 * 60 * 24 * 31 * 6}).value;
  }

  function setCookie(name: string, value: string) {
    const cookie = useCookie(name, { path: '/', maxAge: 60 * 60 * 24 * 31 * 6 });
    cookie.value = value;
  }

  async function setSession(token: string) {

    await setCookieToken(token);

    await refresh();

  }

  async function login(credentials: LoginCredentials) {
    if (isLoggedIn.value) return;

    let data = await useCustomFetch("/api/session", { method: "post", body: credentials });

    // console.log('data');
    // console.log(data);

    // console.log(data.data.value.data);
    await setCookieToken(data.data.value.data.token.bearer);

    await refresh();
  }

  async function resendEmailVerification() {
    return await useCustomFetch<{ status: string }>(
      "/email/verification-notification",
      {
        method: "post",
      }
    );
  }

  async function logout(options: { redirect?: string } = {}) {
    if (!isLoggedIn.value) return;

    await setCookieToken('');

    await useCustomFetch("/api/session", { method: "delete" });
    // user.value = null;
    setDefaultUnit();
    setDefaultUser();

    // const auth_cookie = useCookie("auth:token");
    // auth_cookie.value = null;

    

    await router.push("/");
  }

  async function forgotPassword(email: string) {
    return await useCustomFetch<{ status: string }>("/forgot-password", {
      method: "post",
      body: { email },
    });
  }

  async function resetPassword(credentials: ResetPasswordCredentials) {
    return await useCustomFetch<{ status: string }>("/reset-password", {
      method: "post",
      body: credentials,
    });
  }

  function isVerifiedEmail() {
    return user.value?.is_email_verified ? true : false;
  }

  function goToLogin() {
    useCookie('back_path',{maxAge: 60 * 60 * 24 * 31 * 6}).value = router.currentRoute.value.fullPath;
    router.push("/auth/signin");
  }

  function getBackLoginPath() {
    return useCookie('back_path',{maxAge: 60 * 60 * 24 * 31 * 6}).value;
  }

  // if(!getGuest()) setGuest();

  return {
    user,
    isLoggedIn,
    login,
    setSession,
    setCookieToken,
    resendEmailVerification,
    logout,
    forgotPassword,
    resetPassword,
    refresh,
    getGuest,

    goToLogin,
    getBackLoginPath,
    setDefaultUnit,
    setNutrient,
    setLength,
    setTds,
    setTemperature,
    setVolume,
    setWeight,

    isOwner,
    isAuth,
    isVerifiedEmail,
    getId,
    getName,
    getAvatarLittle,
    getAvatarSmall,
    getLink,
    getStatus,
    getNutrient,
    getLength,
    getTds,
    getTemperature,
    getVolume,
    getWeight,
    getPreset,
    getAccess,
    existsAccess,
    isB2B,

  };
};

export const fetchCurrentUser = async () => {
  try {
    let data = await useCustomFetch<User>("/api/session/user", {
      redirectIfNotAuthenticated: false,
    });
    // console.log('loaded auth user');
    // console.log(data.data.value.data.user);
    return data?.data?.value?.data.user;
  } catch (error: any) {
    // if ([401, 400, 419].includes(error?.response?.status)) return null;
    // throw error;
    return null;
  }
};